<template>
  <div id="app" style="padding: 0 15px">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goSelect()" content="一键报警"> </el-page-header>
      </el-col>
    </el-row> -->

    <div class="container">
      <div class="table">
        <el-table
          :data="AKeyAlarmInfoList"
          :stripe="true"
          :border="true"
          height="100%"
          @row-click="showInfo"
          style="border-radius:10px;cursor:pointer"
        >
          <el-table-column prop="name" label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.wechatUserInfoName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="报警类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="处理状态" align="center">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.dealWith == '已处理' ? 'success' : 'danger'"
              >
                <span>{{ scope.row.dealWith }}</span>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="报警时间" align="center" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="pageAKeyAlarm.current"
          :page-size="pageAKeyAlarm.limit"
          :total="total"
          layout="total, prev, pager, next, jumper"
          @current-change="getPageAKeyAlarm"
          class="pagination"
        ></el-pagination>
      </div>

      <div class="content">
        <div class="describe">
          <!-- 描述信息 -->
          <h1>用户信息:</h1>
          <el-descriptions :column="1" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                用户名:
              </template>
              {{ personInfo.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone"></i>
                手机号:
              </template>
              {{ personInfo.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                所属部门:
              </template>
              {{ personInfo.departmentName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-tickets"></i>
                是否分派:
              </template>
              <span>{{ personInfo.isHandOut }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-location-outline"></i>
                上报地点:
              </template>
              {{ personInfo.location }}
            </el-descriptions-item>
          </el-descriptions>
          <div class="btns">
            <el-button @click="showPersonHistory" plain
              >查看此人报警历史</el-button
            >
            <el-button @click="handOut" plain>分 派</el-button>
            <el-button type="primary" @click="dealWith">处 理</el-button>
          </div>
        </div>

        <div class="video">
          <!-- <div v-if="!videoUrl" class="text">暂无视频</div> -->
          <video :src="baseUrl+videoUrl"></video>
        </div>

        <div class="map-content">
          <maps :longitude="longitude" :latitude="latitude"></maps>
        </div>
      </div>
    </div>

    <!-- 查看人员报警历史记录 -->
    <div>
      <el-dialog
        :title="personInfo.name + '的报警历史记录'"
        :visible.sync="personHistoryDialog"
        width="40%"
      >
        <div class="tablePerson">
          <el-table
            id="table"
            :data="personHistory.AKeyAlarmPersonHistory"
            max-height="800"
            :stripe="true"
            :border="true"
            height="100%"
          >
            <el-table-column prop="name" label="报警类型" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="status" label="处理状态" align="center">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.dealWith == '已处理' ? 'success' : 'danger'"
                >
                  <span>{{ scope.row.dealWith }}</span>
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="报警时间" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>

          </el-table>
          <div style="margin: 50px 0">
            <el-pagination
              :current-page.sync="personHistory.pageAKeyAlarm.current"
              :page-size="personHistory.pageAKeyAlarm.limit"
              :total="personHistory.total"
              layout="total, prev, pager, next, jumper"
              @current-change="getPersonHistory(personHistory.pageAKeyAlarm)"
              class="pagination"
            ></el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="分派任务:" :visible.sync="HandOutDialog" width="20%">
      <div>
        <el-select
          v-model="AKeyAlarmPerson.receivePersonPhone"
          multiple
          placeholder="请选择"
          style="width:100%"
        >
          <el-option
            v-for="item in AKeyAlarmPersonList"
            :key="item.phone"
            :label="item.name"
            :value="item.phone"
          >
          </el-option>
        </el-select>
      </div>
      <div class="confirm" style="width:100%">
        <el-button type="primary" @click="confirm" style="margin:20px 0 0 0;text-align:right">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import aKeyAlarm from "../../api/manageApi/AKeyAlarm";

import AlarmOfMap from '@/components/echarts/AlarmOfMap'

import BaseData from '@/assets/config/BaseData'
export default {
  components:{
    maps:AlarmOfMap,
  },
  data() {
    return {
      longitude: "",
      latitude: "",

      baseUrl: BaseData.baseUrl,
      videoUrl:"",

      //所有一键报警信息
      AKeyAlarmInfoList: [],
      pageAKeyAlarm: {
        current: 1, //当前页
        limit: 15, //每页显示条数
        aKeyAlarmQuery: {},
      },
      //数据总数
      total: 0,
      //分派弹窗
      HandOutDialog: false,
      //查看人员报警历史记录
      personHistoryDialog: false,
      //人员信息
      personInfo: {
        name: "",
        phone: "",
        departmentName: "",
        location: "",
        isHandOut: "",
      },
      //分派
      AKeyAlarmPersonList: [
        {
          name: "",
          phone: "",
        },
      ],
      //分派和处理向后端传的数据
      AKeyAlarmPerson: {
        id: "",
        receivePersonPhone: [],
      },
      status: "",
      //人员报警历史记录
      personHistory: {
        AKeyAlarmPersonHistory: [],
        pageAKeyAlarm: {
          current: 1, //当前页
          limit: 5, //每页显示条数
          name: "", //查询此人的报警记录
          phone: "", //查询此人的报警记录
        },
        total: 0,
      },
    };
  },
  created() {
    //分页查询所有报警记录
    this.getPageAKeyAlarm();
    //查询被分派人员
    this.selectHandOut();
  },
  mounted: function () {
    setTimeout(() => {
      this.getFirst();
    }, 1000);
  },
  methods: {
    //返回
    goSelect() {
      this.$router.push("/Select");
    },
    getFirst() {
      //开局显示第一条数据
      this.personInfo.name = this.AKeyAlarmInfoList[0].wechatUserInfoName;
      this.personInfo.phone = this.AKeyAlarmInfoList[0].phone;
      aKeyAlarm
        .getDepartmentById(this.AKeyAlarmInfoList[0].ciId)
        .then((response) => {
          this.personInfo.departmentName = response.data.data.departmentName;
        });
      this.personInfo.location = this.AKeyAlarmInfoList[0].location;
      this.personInfo.isHandOut =
        this.AKeyAlarmInfoList[0].notifyWechatUserInfoId == null
          ? "未分派"
          : "已分派";
    },
    // 分页查询
    getPageAKeyAlarm() {
      aKeyAlarm.getPageAKeyAlarm(this.pageAKeyAlarm).then((response) => {
        this.AKeyAlarmInfoList = response.data.data.pageAKeyAlarm;
        //条数
        this.total = response.data.data.total;
      });
    },
    //查询被分派人员
    selectHandOut() {
      aKeyAlarm.getAllAkeyAlarmPerson().then((response) => {
        this.AKeyAlarmPersonList = response.data.data.AKeyAlarmPerson;
      });
    },
    //点击表格某一行,查看信息
    showInfo(row, column, event) {
      this.videoUrl = row.videoUrl

      this.AKeyAlarmValue = row;
      this.AKeyAlarmPerson.id = row.id;
      this.personInfo.name = row.wechatUserInfoName;
      this.personInfo.phone = row.phone;
      this.personInfo.isHandOut =
        row.notifyWechatUserInfoId == null ? "未分派" : "已分派";

        this.latitude = row.latitude;
        this.longitude = row.longitude;

      aKeyAlarm.getDepartmentById(row.ciId).then((response) => {
        this.personInfo.departmentName = response.data.data.departmentName;
      });
      this.personInfo.location = row.location;
      //查询该人员所有报警记录 将对象中的name赋值
      this.personHistory.pageAKeyAlarm.name = row.wechatUserInfoName;
      this.personHistory.pageAKeyAlarm.phone = row.phone;


      console.log(this.baseUrl+this.videoUrl);

    },
    //分派任务弹窗
    handOut() {
      this.HandOutDialog = true;
      //先将分派任务选项清空
      this.AKeyAlarmPerson.receivePersonPhone = [];
    },
    //分派任务
    confirm() {
      aKeyAlarm.handOut(this.AKeyAlarmPerson).then((response) => {
        if (response.data.message == "该任务已被分派") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
        if (response.data.message == "分派成功") {
          this.HandOutDialog = false;
          this.personInfo.isHandOut = "已分派";
          this.$message({
            type: "success",
            message: response.data.message,
          });
        } else if (response.data.message == "分派失败") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    //处理
    dealWith() {
      aKeyAlarm.dealWith(this.AKeyAlarmPerson).then((response) => {
        if (response.data.message == "处理成功") {
          this.$message({
            type: "success",
            message: response.data.message,
          });
          //处理完成之后重新查询页面数据
          this.getPageAKeyAlarm();
        } else if (response.data.message == "该任务不存在") {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    // 显示个人历史记录弹框
    showPersonHistory() {
      this.personHistoryDialog = true;
      this.getPersonHistory(this.personHistory.pageAKeyAlarm);
    },
    //分页查询此人报警历史记录并显示dialog框
    getPersonHistory(pageAKeyAlarm) {
      aKeyAlarm
        .getPersonHistory(this.personHistory.pageAKeyAlarm)
        .then((response) => {
          this.personHistory.AKeyAlarmPersonHistory =
            response.data.data.personHistory.records;
          //该人员报警记录条数
          this.personHistory.total = response.data.data.personHistory.total;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 90%;

  .table {
    padding: 10px 0;
    box-sizing: border-box;
    width: 25%;
    border-radius: 15px;
  }
  .pagination {
    width: 25%;
    margin-bottom: 2%;
  }

  .content {
    margin: 10px;
    box-sizing: border-box;
    width: 73%;
    height: 50%;
    // display: flex;
    display: flex;
    flex-wrap: wrap;

    .describe {
      width: 49%;
      padding: 20px;
      box-sizing: border-box;
      background: rgb(248, 248, 248);
      margin-right: 1%;
      border-radius: 10px;
      h1 {
        color: #555;
        font-size: 22px;
        margin: 0 0 15px 10px;
        font-weight: 600;
      }
      .btns {
        text-align: right;
        margin: 20px;
      }
    }
    .video {
      box-sizing: border-box;
      padding: 10px;
      width: 50%;
      height: 100%;
      color: #000;
      border-radius: 10px;
      background: rgb(248, 248, 248);
      box-sizing: border-box;
      border: 1px solid pink;
      video{
        width: 100%;
      }
      .text{
        font-size: 26px;
        text-align: center;
        margin-top: 150px;
        color: #555;
        letter-spacing: 5px;
      }
    }

    // .video {
    //   width: 35%;
    //   border: 1px solid red;
    //   height: 100%;
    //   padding: 10px;
    //   margin: 10px;
    // }

    // .descriptions {
    //   padding: 0 20px 0 0;
    //   margin: 10px;
    //   width: 65%;
    //   height: 100%;
    // }

    .map-content {
      width: 100%;
      height: 100%;
      background: rgb(248, 248, 248);
      margin: 10px 0;
      border-radius: 10px;
      color: #000;
      padding: 10px;

    }
  }
}

.confirm {
  width: 100px;
  margin: 0 auto;
}
.select {
  margin-bottom: 20px;
}

// .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
//   text-align: center;
// }
.check {
  text-align: right;
  margin-top: 10px;
}
.personHistory {
  margin-right: 20px;
}
</style>